$govuk-font-url-function: "url";
$govuk-image-url-function: "url";
$govuk-font-family: 'Rubik', sans-serif;

@font-face {
  font-family: 'Rubik';
  src: url('Rubik-Regular.ttf');
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('Rubik-Bold.ttf');
  font-weight: bold;
  font-display: swap;
}

@import "govuk-frontend/dist/govuk/all";
