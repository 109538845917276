// We might not use all of these, but helpful to have them around should
// we need them
$neu-black:        #25303B;
$neu-blue:         #00A8D7;
$neu-violet-lite:  #c2befa;
$neu-orange:       #F7941D;
$neu-yellow:       #D7DF23;
$neu-violet:       #A092E0;
$neu-green:        #00C08A;

$neu-autocomplete-highlight: lighten($neu-orange, 15%);

$jt-primary: $neu-black;
$jt-secondary: $neu-violet; // beta badge, pretty much exclusively
$logo-width: 150px;
$mobile-logo-width: 120px;
$footer-logo-width: 100px;
$jt-progress-color: $neu-yellow;
$jt-progress-radius: 1px;
$jt-progress-height: 10px;
$jt-border-radius: 0;

$neu-button-border: 3px;

@import 'govuk_frontend';
@import '_shared/forms';
@import '_shared/layout';
@import '_shared/admin';
@import '_shared/landing';

.govuk-flash__notice {
  border-color: $neu-green;
}
/* Tenant-specific overrides go here */
header.govuk-header.jt-public {
  border-bottom: 3px solid $jt-primary;

  .govuk-header__container {
    @include govuk-media-query($until: tablet) {
      padding-bottom: 10px;
      .govuk-header__logo {
        width: $mobile-logo-width;
        .logo img {
          width: $mobile-logo-width ;
        }
      }
    }

    @include govuk-media-query($from: tablet) {
      padding-bottom: 15px;
      .logo img {
        width: $logo-width;
      }
    }
  }
}

main {
  .autocomplete__option {
    &:hover, &--focused {
      color: black;
      background-color: $neu-autocomplete-highlight;
      border-color: $neu-autocomplete-highlight;
    }
  }
}

.govuk-panel {
  &.govuk-panel--confirmation {
    background-color: $neu-green;
    color: $neu-black;
  }
}

.govuk-button {
  box-sizing: border-box;
  border: $neu-button-border solid transparent;
  padding: 0.6em 1.75em;
  font-weight: bold;
  transition: 0.2s;

  &:hover {
    border: $neu-button-border solid $jt-primary;
    color: $neu-black;
    background-color: white;
    text-decoration: underline;
  }
}

footer.govuk-footer {
  border-top: 3px solid $jt-primary;
  padding-top: 35px;
  background-color: #FFF;

  .govuk-footer__meta {
    img {
      width: $footer-logo-width;
    }

    .govuk-footer__meta-item {
      &.govuk-footer__meta-issues {
        background-color: $neu-violet-lite;
        display: inline-block;
        text-align: center;
        width: 100%;
        padding: 0 1em;
        @include govuk-media-query($from: desktop) { padding: 0 15%; }
      }

      &.govuk-footer__meta-logo {
        display: none;
        @include govuk-media-query($from: tablet) { display: block; }
      }
    }
  }
}

